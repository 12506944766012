<template>
  <!-- 一分钱特惠福利，广通刷单页，已转移到小程序，现废弃 -->
  <div class="container">
    <img
      class="top"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/yifen/yifen_top01.png"
    />
    <img
      class="bg"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/yifen/yifen_bg01.png"
    />

    <div class="form">
      <van-field
        v-model="form.phone_number"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
      />
      <img
        class="submit"
        @click="onSubmit"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/yifen/yifen_submit.png"
      />

      <div class="price">0.01元/月</div>

      <div class="checked">
        <van-checkbox
          checked-color="#ffd614"
          v-model="checked"
          icon-size="14px"
        >
          &nbsp;我已阅读并同意<span class="book" @click="dialogInfo"
            >《服务协议》</span
          >
        </van-checkbox>
      </div>
    </div>
    <div class="title">
        ——————&nbsp; <span> 活动规则 </span> &nbsp;——————
    </div>
    <div class="info">
      <div class="text">【商品名称】：特惠福利会员</div>
      <div class="text">【计费方式】：支付宝连续包月</div>
      <div class="text">【资费】：0.01元/月</div>
      <div class="text">【权益说明】：各类优惠满减券领取及每月有机会抽取5元话费</div>
      <div class="text">【会员使用说明】：领取满减券后获得券码去对应的平台使用</div>
      <div class="text">【5元红包使用说明】：</div>
      <div class="text">
        ① 红包自用户领取起30天内有效，领取后发放到支付宝卡包，红包不可拆分，不得提现、无法转赠、不得为他人付款，过期自动作废，无法使用，同时也不予补发。
      </div>
      <div class="text">
        ② 该红包可用场景为支付宝话费充值-话费常规充值/话费代扣充值(自动充),使用支付宝选择指定银行及卡类型且单笔实际支付金额大于或者等于5.01元时可抵扣使用(实际使用范围以红包可抵扣为准)。使用红包的订单若发生退款，订单可退金额及红包可退面额以消费者的退款页面信息为准。退回的红包若在有效期内则可继续使用，且使用规则不变；退回的红包若已过有效期则无法使用，且不予补发。
      </div>
    </div>
    <img
      class="bottom"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/yifen/yifen_bg02.png"
    />
    <img
      class="bottom"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/yifen/yifen_bg03.png"
    />
    <v-dialog :visibility.sync="visibility" :aitosuper= true />
  </div>
</template>

<script>
import { alipayPay, codeDlipayPay } from "@/api/guangtong_equity_center.js";
import VDialog from "./dialog/index.vue";
export default {
  data() {
    return {
      form: {
        phone_number: "",
      },
      sendAuthCode: true,
      checked: false,
      auth_time: 0,
      state: false,
      visibility: false,
    };
  },
  components: {
    VDialog,
  },
  mounted() {
  const metaTag = document.createElement("meta");
    metaTag.name = "referrer";
    metaTag.content = "no-referrer";
   document.getElementsByTagName("head")[0].appendChild(metaTag);
   },
   beforeDestroy() {
     const metaTag = document.querySelector('meta[name="referrer"]');
     if (metaTag) {
       document.getElementsByTagName("head")[0].removeChild(metaTag);
    }
   },
  methods: {
    async onSubmit() {
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone_number } = this.form;
      if (!filterPhone.test(phone_number)) {
        this.$toast.fail("手机号码有误请重新输入");
      } else if (!this.checked) {
        this.$toast.fail("请勾选“我已经阅读并同意《服务协议》”");
      } else {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          mask: true,
          message: "加载中...",
        });
        const res = await alipayPay({
          phone_number,
          item_name: "特惠福利会员",
        });
        this.$toast.clear();
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message,
          });
        } else {
          location.href = `alipays://platformapi/startapp?appId=20000067&url=${encodeURIComponent(
            res.sign_str
          )}`;
        }
      }
    },
    dialogInfo() {
      this.visibility = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #7676FF;
  padding-bottom: 20px;
  .top,.card{
    width: 100%;
  }
  .bg{
    width: 94vw;
    margin: -300px 3vw 0;
  }

  .form {
    height: 400px;
    width: 750px;
    padding-top: 33px;
    box-sizing: border-box;

    ::v-deep .van-cell {
      width: 620px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 50px;
      margin: 0px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 23px;

      &.input-code {
        margin-top: 20px;

        .code {
          width: 190px;
          height: 100px;
          text-align: center;
          line-height: 100px;
          color: #6D48D8;
          font-size: 28px;
          font-weight: normal;
          background-color: #DEDCFF;
        }
      }

      .van-field__body {
        height: 100%;

        input {
          height: 100%;
        }
      }

      &::after {
        border: none;
      }

      .van-field__control {
        color: #000;
        font-weight: normal;
        &::-webkit-input-placeholder {
          font-size: 34px;
          font-weight: 40;
        }
      }
    }

    .submit {
      width: 620px;
      display: block;
      margin: 50px auto 0;
    }
    .price{
      width: 100%;
      height: 40px;
      margin: 10px 0 0;
      text-align: center;
      color: #fff;
      font-size: 28px;
    }

    .checked {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-weight: 400;
      padding: 10px 0;

      ::v-deep .van-checkbox {
        width: auto;
        .van-checkbox__icon .van-icon{
          border: 1px solid #fff;
        }

        &__label {
          margin-left: 4px;
          color: #fff !important;

          .book {
            color: #FEED72;
          }
        }
      }
    }
  }
  .title {
    width: 80vw;
    height: 60px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    text-align: center;
    line-height: 60px;
   
    margin: 10px auto 0;
    color: #fff;
    span{
      font-size: 34px;
      font-weight: bold;
    }
  }
  .info {
    width: 96vw;
    border-radius: 20px;
    margin: 0 auto;
    box-sizing: border-box;
    font-size: 24px;
    font-weight: 500;
    padding: 20px 40px 60px;
    line-height: 40px;
    color: #fff;

    .text {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
    }
  }
  .bottom{
    width: 94vw;
    margin: 10px 3vw;
  }
}
</style>
